import React, { Component, createRef } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Modal from "./Modal";

const SITE_KEY = "6LdpLVQcAAAAAA4JO4ERytv8ul7TwzPtfEMZRxZo";

export default class ContactFormClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this._validationSchema = Yup.object().shape({
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.number()
        .typeError("- That doesn't look like a phone number")
        .positive("- A phone number can't start with a minus")
        .integer("- A phone number can't include a decimal point")
        .min(8),
      token: Yup.string().required(),
    });
    this._initialValues = {
      token: "",
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      phone: "",
      message: "",
      hearus: "",
    };
    this._reCaptchaRef = createRef();

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const MyTextInput = ({ label, ...props }) => {
      const [field, meta] = useField(props);
      return (
        <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <input className="text-input" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="error text-red-500 text-xs pl-1">*{meta.error}</div>
          ) : null}
        </>
      );
    };
    return (
      <Formik
        validationSchema={this._validationSchema}
        initialValues={this._initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          // console.log(this.state.show);
          // console.log("registerHandler received:", values);

          try {
            const response = await axios.post(
              "https://api.ipxon.net/public/conexum/forms/contact",
              JSON.stringify(values, null, 2),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log(response);
          } catch (e) {
            // alert(
            //   "Email could not be sent, please try again and make sure that all required fields are present, and also, make sure to use the captcha."
            // );
          } finally {
            this.showModal();
            setSubmitting(false);
          }
        }}
      >
        {(props) => {
          return (
            <Form
              onSubmit={props.handleSubmit}
              className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <MyTextInput
                  label="First Name"
                  name="firstname"
                  type="text"
                  placeholder="John"
                  onChange={props.handleChange}
                  className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
              <div>
                <MyTextInput
                  label="Last Name"
                  name="lastname"
                  type="text"
                  placeholder="Doe"
                  onChange={props.handleChange}
                  className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
              <div className="sm:col-span-2">
                <MyTextInput
                  label="Email"
                  name="email"
                  type="text"
                  placeholder="john.doe@email.com"
                  onChange={props.handleChange}
                  className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
              <div className="sm:col-span-2">
                <MyTextInput
                  label="Company"
                  name="company"
                  type="text"
                  placeholder="John's Doe Company"
                  onChange={props.handleChange}
                  className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
              <div className="sm:col-span-2">
                <MyTextInput
                  label="Phone"
                  name="phone"
                  type="text"
                  placeholder="+123 45 678 910"
                  onChange={props.handleChange}
                  className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="how-can-we-help">How can we help you?</label>
                  <span id="how-can-we-help-description">
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <Field
                    name="message"
                    as="textarea"
                    rows="4"
                    className="block w-full shadow-sm sm:text-sm p-1 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="sm:col-span-2 pt-4">
                  <MyTextInput
                    label="How did you hear from us?"
                    name="hearus"
                    type="text"
                    placeholder="Eg: Facebook, Instagram, Google"
                    onChange={props.handleChange}
                    className="block w-full shadow-sm sm:text-sm p-1 h-8 bg-gray-100 focus:ring-blue-400 focus:border-blue-500 border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <ReCAPTCHA
                  ref={this._reCaptchaRef}
                  sitekey={SITE_KEY}
                  onChange={(value) => {
                    props.setFieldValue("token", value);
                    props.setSubmitting(false);
                  }}
                  size="normal"
                />
                <button
                  type="submit"
                  disabled={props.isSubmitting}
                  className="inline-flex justify-center items-center p-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700  focus:ring-offset-2 h-8 focus:ring-blue-400"
                >
                  SUBMIT
                </button>
                {this.state.show === true && <Modal show={this.state.show} />}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
