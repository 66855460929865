import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import ContactFormClass from "../components/ContactFormClass";

const ContactUsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "contact-us-image.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <main className="font-mulish w-full bg-gradient-to-r from-blue-900 to-blue-800 shadow-inner">
      <title>Conexum - Contact us</title>
      <div>
        <header id="header" className="bg-black h-1/5 transition-all">
          <Navbar />
        </header>
        <div className="relative bg-gray-50">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={imageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Conexum's cowork in Uruguay"
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8 flex justify-center">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="flex uppercase text-3xl gap-1 flex-col md:text-5xl font-bold text-blue-900 tracking-tighter">
                  <span>
                    CONTACT <span className="text-blue-300">US</span>
                  </span>
                  <span className="font-light tracking-tighter text-blue-400">
                    Let's work together
                  </span>
                </h2>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We’d love to hear from you! Send us a message using the form.
                  At Conexum we are always willing to improve, either by your
                  comments, or by your business!
                </p>
                <ContactFormClass />
                <div className="flex flex-col mt-16 text-lg text-gray-500 gap-2">
                  <p className="font-bold">Conexum Inc.</p>
                  <p className="font-thin">
                    2637 E Atlantic Blvd Pompano Beach, FL 33062
                  </p>
                  <p className="font-thin">hello@conexum.co</p>
                  <p className="font-thin">+1 888-703-5375</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default ContactUsPage;
